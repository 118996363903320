<template>
  <div class="monograph-detail-container">
    <div class="side" v-if="hide">
      <div class="blog-title">
        文章目录<span @click="hide = false">&lt;</span>
      </div>
      <div class="contents" v-html="detailsData.catalogue"></div>
    </div>
    <div class="line" v-else>
      <div class="icon" @click="hide = true">&gt;</div>
      <div class="catalogue">文章目录</div>
    </div>
    <div class="container">
    <p class="invalid">该页面为预览效果，短期内将会失效</p>
      <div class="head">
        <div class="left">
          <div class="top">
            <span class="type">[专著]</span>
            <span class="title">{{ detailsData.monographName }}</span>
          </div>
          <IconButton1
            v-if="detailsData.id"
            :every="tempData"
            :curcer="detailsData.id"
          ></IconButton1>
          <div class="auther">
            作 者：
            <span v-for="(item, i) in detailsData.articleAuthors" :key="i">
              {{ item.authorName }}&nbsp;
            </span>
          </div>
          <p>
            <span>出版社：</span>
            {{ detailsData.press }}
          </p>
          <p><span>版&nbsp;&nbsp;次：</span>{{ detailsData.edition }}</p>
          <p><span>页&nbsp;&nbsp;数：</span>{{ detailsData.pageNumber }}</p>
          <p>
            <span>字&nbsp;&nbsp;数：</span>{{ detailsData.monographLenght }}
          </p>
          <p>
            <span>出版时间：</span>{{ detailsData.publishTime.slice(0,10) }}
          </p>
          <p><span>ISBN：</span>{{ detailsData.isbn }}</p>
          <p><span>分类号：</span>{{ detailsData.classifyNumber }}</p>
        </div>
        <div class="right">
          <img :src="$global.imgUrl + detailsData.cover" alt="" />
        </div>
      </div>
      <div class="content">
        <div>内容简介：</div>
        <p>{{ detailsData.artSummary }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import IconButton1 from "../components/IconButton1.vue";
import details from "@/mixins/details.js";
export default {
  components: {
    IconButton1,
  },
  mixins: [details],
  data() {
    return {
      detailsData: {},
      otherList: [],
      hide: false,
    };
  },
  computed: {
    ...mapState(["table"]),
  },
  created() {
    this.getDetails({
      articleType: this.$route.query.articleType,
      articleId: this.$route.query.articleId,
      projectId: 3,
    });
  },
};
</script>

<style lang="scss" scoped>
.monograph-detail-container {
  display: flex;
  .line {
    position: relative;
    height: 115px;
    border-left: 1px dashed #999999;
    padding: 10px 0;
    margin-right: 27px;
    .catalogue {
      writing-mode: vertical-lr;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-top: 35px;
      line-height: 1;
    }
    .icon {
      display: inline-block;
      width: 13px;
      height: 26px;
      background: #00a4ff;
      position: absolute;
      left: 0;
      color: #fff;
      border-top-right-radius: 30%;
      border-bottom-right-radius: 30%;
      cursor: pointer;
    }
  }
  .side {
    width: 290px;
    min-height: 600px;
    background: #ffffff;
    border: 1px dotted #999999;
    border-radius: 14px;
    margin-right: 40px;
    padding: 29px;
    position: relative;
    &.hide {
      display: none;
    }
    .blog-title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 24px;
      span {
        display: inline-block;
        width: 13px;
        height: 26px;
        background: #00a4ff;
        position: absolute;
        right: 0;
        color: #fff;
        border-top-left-radius: 30%;
        border-bottom-left-radius: 30%;
        cursor: pointer;
      }
    }
    .contents {
      ::v-deep table {
        border-collapse:collapse;
        border-spacing:0;
        th {
          width: 190px;
          height: 35px;
          min-height: 30px;
          border: 1px solid #ccc;
          padding: 3px 5px;
          background-color: #f1f1f1;
        }
        td {
          width: 190px;
          height: 35px;
          min-height: 30px;
          border: 1px solid #ccc;
          padding: 3px 5px;
        }
      }
    }
    div {
      p {
        font-size: 14px;
        color: #333;
        white-space: nowrap;
        margin: 20px 0;
        cursor: pointer;
      }
    }
  }
  .container {
    width: 1400px;
    .invalid {
      margin: 0 auto 50px;
      text-align: center;
      width: 396px;
      height: 45px;
      line-height: 45px;
      background: #f9f9f9;
      border-radius: 4px;
      font-size: 14px;
      font-family: Source Han Sans SC;
      font-weight: 400;
      color: #333333;
    }
  }
  .head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #d6d6d6;
    padding-bottom: 33px;
    .right {
      img {
        width: 200px;
        height: 279px;
      }
    }
    .top {
      display: flex;
      align-items: center;
      .type {
        font-size: 30px;
        color: #999999;
      }
      .title {
        font-size: 30px;
        color: #333333;
        margin: 0 5px;
      }
      .column {
        display: inline-block;
        width: 76px;
        height: 30px;
        background: #00a4ff;
        border: 1px solid #00a4ff;
        border-radius: 11px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 30px;
      }
    }
    p {
      margin: 15px 0;
      span {
        font-size: 14px;
        color: #999999;
        margin-top: 14px;
      }
    }
    .auther {
      font-size: 14px;
      color: #999999;
      margin-top: 14px;
      span {
        font-size: 14px;
        margin-right: 10px;
      }
    }
    .unit {
      margin-top: 14px;
      font-size: 14px;
      color: #999999;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .content {
    border-bottom: 1px solid #d6d6d6;
    padding: 42px 0;
    color: #333;
    font-size: 16px;
    div {
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
</style>
